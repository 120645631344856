<!-- 主页 -->
<template>
  <div class="home">
    <el-container>
      <el-header>
        <div style="height: 50px;margin-top: 5px;display: flex;">
          <img src="../assets/函驿通logo.png" alt style="height: 100%;" />
          <div
            style="font-family: 黑体;font-weight: 400;font-size: 32px;letter-spacing: 3px;color: rgb(51, 126, 190);"
          >函驿通电子保函服务平台</div>
        </div>
        <div style="margin-top: 5px;">
          <el-button type="text" style="margin-right: 20px;" @click="returnMain">返回首页</el-button>
          <el-dropdown trigger="click">
            <span class="el-dropdown-link">
              个人中心
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="account">账号信息</el-dropdown-item>
              <el-dropdown-item @click.native="quit">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-header>
      <el-container>
        <el-aside width="290px" style="display:flex;flex-direction:column;justify-content:space-between;">
          <div>
            <div style="width: 90%;margin: 0 auto;border-bottom: 1px solid #fff;">
              <div style="text-align: center;font-size: 50px;color: #fff;">
                <i class="el-icon-s-custom"></i>
              </div>
              <div
                style="text-align: center;font-size: 18px;color: #fff;margin-bottom: 10px;"
              >{{this.firmName}}</div>
              <!-- <div style="text-align: center;margin-top: 10px;margin-bottom: 10px;">
                <el-button type="success" round class="topButton">CA用户</el-button>
                <el-button type="success" round class="topButton">营业执照</el-button>
              </div>-->
            </div>
            <div style="width: 90%;margin: 0 auto;border-bottom: 1px solid #fff;line-height: 30px;">
              <div style="font-size: 12px;color: #fff;">统一社会信用代码：{{this.com_code}}</div>
              <div style="font-size: 12px;color: #fff;">企业地址：{{this.com_address}}</div>
              <div style="font-size: 12px;color: #fff;">法人姓名：{{this.legal_person}}</div>
              <div style="font-size: 12px;color: #fff;">法人身份证号：{{this.card_code}}</div>
              <!-- <div style="font-size: 12px;color: #fff;">联系人电话：123XXXX5678</div> -->
            </div>
            <div style="width: 90%;margin: 0 auto;border-bottom: 1px solid #fff;text-align:right;">
              <el-button
                style="margin-top: 20px;margin-bottom: 20px;"
                @click="goToMessage"
              >{{this.buttonText}}</el-button>
            </div>
            <el-row>
              <el-col>
                <el-menu
                  :default-active="this.$route.path"
                  class="el-menu-vertical-demo"
                  text-color="#fff"
                  active-text-color="#ffd04b"
                  unique-opened
                  router
                >
                  <el-submenu index="1">
                    <template slot="title">
                      <i class="el-icon-document" style="color:#fff;"></i>
                      <span>投标保函</span>
                    </template>
                    <el-menu-item-group>
                      <el-menu-item index="/online">在线申请</el-menu-item>
                    </el-menu-item-group>
                  </el-submenu>
                  <el-submenu index="2">
                    <template slot="title">
                      <i class="el-icon-house" style="color:#fff;"></i>
                      <span>履约保函</span>
                    </template>
                    <el-menu-item-group>
                      <el-menu-item index="/agreement">立即申请</el-menu-item>
                    </el-menu-item-group>
                  </el-submenu>
                  <el-submenu index="3">
                    <template slot="title">
                      <i class="el-icon-copy-document" style="color:#fff;"></i>
                      <span>农民工工资保函</span>
                    </template>
                    <el-menu-item-group>
                      <el-menu-item index="/salary">立即申请</el-menu-item>
                    </el-menu-item-group>
                  </el-submenu>
                  <el-menu-item index="/history">
                    <i class="el-icon-chat-line-square" style="color:#fff;"></i>
                    <span slot="title">保函历史</span>
                  </el-menu-item>
                  <el-submenu index="4">
                    <template slot="title">
                      <i class="el-icon-house" style="color:#fff;"></i>
                      <span>发票管理</span>
                    </template>
                    <el-menu-item-group>
                      <el-menu-item index="/invoice">发票申请</el-menu-item>
                    </el-menu-item-group>
                    <el-menu-item-group>
                      <el-menu-item index="/kaipiao">开票信息管理</el-menu-item>
                    </el-menu-item-group>
                  </el-submenu>
                  <!-- <el-menu-item index="/invoice">
                    <i class="el-icon-chat-line-square" style="color:#fff;"></i>
                    <span slot="title">发票申请</span>
                  </el-menu-item> -->
                  <!-- <el-submenu index="5">
                    <template slot="title">
                      <i class="el-icon-setting" style="color:#fff;"></i>
                      <span>发票管理</span>
                    </template>
                    <el-menu-item-group>
                      <el-menu-item index="5-1">申请发票</el-menu-item>
                      <el-menu-item index="5-2">已开发票管理</el-menu-item>
                    </el-menu-item-group>
                  </el-submenu>-->
                </el-menu>
              </el-col>
            </el-row>
          </div>
          <div style="margin-bottom:20px;text-align:center;color:#fff;font-size:14px;">
            <el-link :underline="false" href="https://beian.miit.gov.cn" target="_blank" style="color:#fff">冀ICP备19022994号</el-link>
          </div>
        </el-aside>
        <el-main style="padding-bottom: 0;">
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
//这里导入其他文件（组件，工具js，第三方插件js，json文件，图片文件等等）
//import 《组件名称》 from '《组件路径》';
import Utils from "../components/util";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},

  // 组件名称
  name: "",

  // 这里存放数据
  data() {
    return {
      firmName: "",
      com_code: "",
      com_address: "",
      legal_person: "",
      card_code: "",
      buttonText: "查看企业信息",
    };
  },

  // 监听属性 类似于data概念
  computed: {},

  // 监控data中的数据变化
  watch: {},

  // 方法集合
  methods: {
    // 点击修改企业信息
    goToMessage() {
      if (window.sessionStorage.getItem("type") == "account") {
        this.$router.push("/enterprise");
      } else {
        this.$router.push("message");
      }
    },

    account() {
      this.$router.push("/account");
    },

    // 点击退出
    quit() {
      this.$confirm("此操作将退出系统, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          window.sessionStorage.clear();
          this.$message({
            type: "success",
            message: "退出成功",
          });
          this.$router.push("/login");
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消退出",
          });
        });
    },

    // 返回首页
    returnMain() {
      this.$router.push("/main");
    },

    getEnterpriseInformation() {
      var type = window.sessionStorage.getItem("type");
      if (type == "CA") {
        var params = {
          CAKeyId: window.sessionStorage.getItem("firmName"),
          from: 10,
          CAKeyEncStr: window.sessionStorage.getItem("date"),
        };
      } else if (type == "CFCA") {
        var params = {
          CAKeyId: window.sessionStorage.getItem("firmName"),
          from: 10,
          CAKeyEncStr: window.sessionStorage.getItem("content"),
        };
      } else {
        var params = {
          handsetNum: window.sessionStorage.getItem("phone"),
          from: 10,
        };
      }
      this.axios({
        method: "GET",
        url: "/newGuarantee.do?existEnterpriseInfo",
        params: params,
      }).then((res) => {
        if (res.data.data.apply) {
          this.buttonText = "查看企业信息";
        } else {
          this.buttonText = "修改企业信息";
        }
        if (res.data.data.hasExist) {
          if (window.sessionStorage.getItem("type") == "account") {
            window.sessionStorage.setItem("com_name",res.data.data.info.com_name)
            this.firmName = window.sessionStorage.getItem("username");
            window.sessionStorage.setItem("socialCode", res.data.data.info.com_code);
            this.com_code = res.data.data.info.com_code;
            this.com_address = res.data.data.info.com_address;
            this.legal_person = res.data.data.info.legal_person;
            let str = res.data.data.info.card_code;
            str = str.replace(/(\w{6})\w*(\w{4})/, "$1******$2");
            this.card_code = str;
          } else {
            this.firmName = window.sessionStorage.getItem("username");
            this.com_code = window.sessionStorage.getItem("socialCode");
            this.com_address = window.sessionStorage.getItem("com_address");
            this.legal_person = window.sessionStorage.getItem("legal_person");
            let str = window.sessionStorage.getItem("card_code");
            str = str.replace(/(\w{6})\w*(\w{4})/, "$1******$2");
            this.card_code = str;
          }
        }
      });
    },
  },

  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {},

  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    
    this.getEnterpriseInformation();
    var that = this;
    Utils.$on("demo", function () {
      that.getEnterpriseInformation();
    });
    if (window.sessionStorage.getItem("menu") == "login") {
      this.$alert('1.请您使用基本户支付</br>2.支付完成后请务必上传支付凭证，并点击“提交”', '提示', {
        confirmButtonText: '确定',
        dangerouslyUseHTMLString: true,
        type: 'warning',
        callback: action => {
          window.sessionStorage.setItem("menu", "homePage");
        }
      });
    }
  },

  // 生命周期 - 创建之前
  beforeCreate() {},

  // 生命周期 - 挂载之前
  beforeMount() {},

  // 生命周期 - 更新之前
  beforeUpdate() {},

  // 生命周期 - 更新之后
  updated() {},

  // 生命周期 - 销毁之前
  beforeDestroy() {},

  // 生命周期 - 销毁完成
  destroyed() {},

  // 如果页面有keep-alive缓存功能，这个函数会触发
  activated() {},

  // keep-alive缓存离开之后触发
  deactivated() {},
};
</script>

<style scoped>
.home {
  height: 100%;
}
.el-header,
.el-footer {
  color: #333;
  line-height: 50px;
  display: flex;
  justify-content: space-between;
}

.el-aside {
  background-color: rgb(51, 126, 190);
  color: #333;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.el-main {
  background-color: #e9eef3;
  color: #333;
}

.el-container {
  height: 100%;
}
.el-menu-vertical-demo {
  border: none;
  background-color: rgb(51, 126, 190);
}
.topButton {
  width: 65px;
  height: 25px;
  font-size: 12px;
}
.el-button.is-round {
  padding: 0;
}
.el-button + .el-button {
  margin-left: 30px;
}
.el-dropdown {
  color: #409eff;
}
.el-dropdown-link {
  cursor: pointer;
}
</style>